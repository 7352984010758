<template>
  <div class="mt-12">
     <div class="card bg-white rounded-xl p-4 ">
      <div class="relative">
        <div class="card top_primary_card bg-primary font-bold text-white py-2 px-4 rounded-xl absolute -top-11 w-full">
          
          <div class="h-8 relative flex items-center justify-between">
            <div>
              Job list ({{totalJobCount}})
            </div>
            <!-- <div class="relative">
                <div class=" text-primary heading-3 cursor-pointer bg-white px-3 py-1 rounded-xl" @click.stop="showMenuList = true">
                  <i class="fas fa-ellipsis-v"></i>
                </div>
                <div v-if="showMenuList">
                  <div v-click-outside="closeList">
                    <div class=" bg-white absolute z-10 right-3 shadow-2xl rounded top-10 w-40">
                        <div class="p-2 text-text2 font-medium text-center">
                          <div class="p-2 cursor-pointer hover:bg-blue-50" @click="sendEmailReq = true">
                            <span class="py-1">Export Job Data</span>
                          </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div> -->
          </div>
        </div>
      </div>
      <div v-if="$route.name !== 'job'">
        <JobList @jobcount="getJobCount" />
      </div>
      <div v-if="$route.name === 'job'">
        <div class="mt-4 p-2 pb-0">
          <div class="flex flex-wrap mb-2 items-center">
            <!-- <div class="pr-2  text-gray4 heading-5">Entity:</div> -->
            <div class="" v-for="(status, index) in entStatusList" :key="index" >
              <StatusBtn class=" whitespace-nowrap" @buttonAction="toggleActiveAndInactiveA(index, status)" :selected="status.selected" :btnText="status.displayText" :lastInlist="entStatusList.length === index + 1"/>     
            </div>
          </div>
          <div class="flex flex-wrap justify-between items-center ">
            <div class="w-72 mb-4">
              <SearchTextInput @searchCall="searchCall" :placeholderText="`Search Job...`"/>
            </div>
            <div class="mb-4">
              <Button class="mr-4" @buttonAction="CreateNewjob(false)" :btnSize="'large'" :textColor="'white'" :btnColor="'primary'" :btnText="'Create Job'"/>
            </div>
          </div>
        </div>
        <div>
          <div style="overflow-y: auto; white-space: nowrap;" class="" v-if="visitLIst.length > 0">
            <div v-for="(data, index) in visitLIst" :key="index"  class="cust_card m-2  heading-3 border-b border-gray1  bg-white  text-sm px-3 py-3 items-center hover:bg-gray1 cursor-pointer" @click="showJobDetail(data.jobId)">
              <div class="">
                <!-- <p class="  text-text1 font-bold">{{}}</p> -->
                <div class="flex justify-between items-center">
                  <p class="text-gray4 heading-5">#{{data.jobNumber}}</p>
                  <div class="text-gray4 heading-5 flex items-center">
                    {{data.dateStatus}}
                    <span v-if="data.dateStatus === 'Unschedule'" class="text-warning h-5 w-5 pl-1"><i class="fa-solid fa-square-full"></i></span>
                    <span v-if="data.dateStatus === 'Late'" class="text-error h-5 w-5 pl-1"><i class="fa-solid fa-square-full"></i></span>
                    <span v-if="data.dateStatus === 'Today'" class="text-secondary h-5 w-5 pl-1"><i class="fa-solid fa-square-full"></i></span>
                    <p v-if="data.dateStatus === 'Upcoming'" class="text-primary h-5 w-5 pl-1"><i class="fa-solid fa-square-full"></i></p>
                  </div>
                </div>
                <div class="flex justify-between items-center">
                  <p class="text-text1 heading-4 py-1 font-semibold whitespace-pre-line">{{data.jobTitle}}</p>
                </div>
                <div class="flex justify-between items-center">
                  <p class="text-gray4 heading-5">{{data.companyName}}</p>
                  <p class="text-error heading-5" v-if="data.invoiceCount > 0">{{data.invoiceCount}} not invoiced</p>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <NoRecords :alertMessage="`No records found.`" />
          </div>
          <div class=" mt-4">
            <Pagination
              :totalDataCount="totalJobCount"
              :resetPagination="resetPaginationValues"
              @callApiAsPerPagination="getLeadDataFromPagination"/>
          </div>
        </div>
      </div>
     </div>
     <div class="popup_overlay relative px-4" v-if="sendEmailReq">
        <div style="width: 440px;" class="custom_dialog rounded-xl h-min p-4 ">
            <div  class="mb-4 font-semibold">
              <p class="heading-4 text-text1">Export Job Data?</p>
            </div>
            <div  class="mb-5 font-bold">
              <p class="heading-6 text-text1">System Will send excel file in email based on applied filter</p>
            </div>
            <div class="flex gap-2 justify-end">
              <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'No'" @buttonAction="closeBtn"/>
              <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'success'" :btnText="'Send Request'" @buttonAction="sendReqBtn"/>
            </div>
        </div>
      </div>
   </div>
</template>
<script>
import SearchTextInput from '@/View/components/searchTextInput.vue'
var moment = require('moment')
import NoRecords from '@/View/components/noRecords.vue'
import MyJobApp from '@/api/MyJobApp.js'
import Button from '@/View/components/globalButton.vue'
import deboucneMixin from '@/mixins/debounce.js'
import Pagination from '@/View/components/pagination.vue'
import StatusBtn from "@/View/components/statu-filter-btn.vue";
import JobList from './components/JobList.vue'
export default {
  name: "lead",
  components: {
    JobList,
    Button,
    StatusBtn,
    NoRecords,
    Pagination,
    SearchTextInput,
  },
  mixins: [deboucneMixin],
  data() {
    return {
      entStatusList: [
        {
          vistStatusId: 1,
          displayText: 'Action Required',
          id: 'actionRequired',
          selected: false,
        },
        {
          vistStatusId: 2,
          displayText: 'Upcoming',
          id: 'upcoming',
          selected: false,
        },
        {
          vistStatusId: 3,
          displayText: 'Today',
          id: 'today',
          selected: false,
        },
        {
          vistStatusId: 4,
          displayText: 'Late',
          id: 'late',
          selected: false,
        },
        // {
        //   vistStatusId: 5,
        //   displayText: 'Running',
        //   id: 'running',
        //   selected: false,
        // },
        {
          vistStatusId: 6,
          displayText: 'Archived',
          id: 'archived',
          selected: false,
        },
        // {
        //   vistStatusId: 7,
        //   displayText: 'Not Assigned',
        //   id: 'notAssignee',
        //   selected: false,
        // },
        {
          vistStatusId: 8,
          displayText: 'Unscheduled',
          id: 'unschedule',
          selected: false,
        },
      ],
      empStatusList: [],
      searchForJob: '',
      visitLIst: [],
      searchForQuotation: '',
      getJobDebounce: null,
      resetPaginationValues: false,
      addLead: false,
      totalJobCount: 0,
      filterObject: {
        start: 0,
        length: 20,
        isActive: true,
        exportData: false,
        sortBy: 'jobNumber',
        sortOrder: 'DESC',
        entStatusList: [],
        empStatusList: [],
        customerId: '',
        from: '',
        to: '',
      },
      showMenuList: false,
      sendEmailReq: false,
    };
  },
  created() {},
  mounted() {
    this.$root.$on("addCustomerHandler", (response) => {
      console.log(response);
      if (response) {
        this.getVisitList()
      }
      this.addLead = false
    });
     this.getJobDebounce = this.debounce(function () {
      this.resetPaginationValues = true
    }, 500)
    this.getVisitList()
  },
  beforeDestroy() {},
  methods: {
  closeBtn () {
    this.sendEmailReq = false
  },
  sendReqBtn () {
    this.sendEmailReq = false
    this.showMenuList = false
    this.filterObject.exportData = true
    this.getVisitList(true)
  },
  closeList () {
    this.showMenuList = false
  },
    CreateNewjob () {
      this.$router.push({name: 'addNewJob', query: {customerId: this.customerDetailId}})
    },
    showJobDetail (id) {
      this.$router.push({name: 'jobDetail', params: {jobId: id}})
    },
    toggleActiveAndInactiveA (id, dataVal) {
      this.entStatusList.map((data, i) => {
          if (dataVal.id === data.id) {
            if (this.entStatusList[i].selected) {
               this.filterObject.entStatusList = []
               this.entStatusList[i].selected = false
            } else {
                this.filterObject.entStatusList = data.id
               this.entStatusList[i].selected = true
            }
          } else {
            this.entStatusList[i].selected = false
          }
      })
      this.resetPaginationValues = true
      // this.getVisitList()
    },
    searchCall (data) {
      this.searchForJob = data
      this.getJobDebounce()
    },
    getLeadDataFromPagination (data) {
      this.filterObject.start = data.offset
      this.filterObject.length = data.limit
      this.getVisitList(true)
    },
    getJobCount (data) {
      this.totalJobCount = data
    },
    getVisitList (showlistLoader) {
      if (showlistLoader && this.searchForQuotation !== '') {
        this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      }
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      MyJobApp.getJobList(
        this.filterObject.start,
        this.filterObject.length,
        this.searchForJob,
        this.filterObject.sortBy,
        this.filterObject.sortOrder,
        this.filterObject.entStatusList,
        0,
        this.filterObject.exportData,
        '',
        '',
        response => {
          if (this.filterObject.exportData) {
          this.$store.dispatch("SetLoader", { status: false, message: "" });
          this.filterObject.exportData = false
          } else {
            this.totalJobCount = response.Data.count
            let temp = response.Data.tableRow !== null ? response.Data.tableRow : []
            for (let index = 0; index < temp.length; index++) {
              let currentDate = new Date();
              currentDate.setHours(0, 0, 0, 0);
              currentDate = new Date(currentDate);
              const cFormated = moment(currentDate).format('YYYY-MM-DD');
              if (temp[index].isUnschedule) {
                temp[index].dateStatus = 'Unschedule';
              } else {
                if ( moment(new Date(temp[index].nextVisitDateTime)).format('YYYY-MM-DD',) === cFormated) {
                  temp[index].dateStatus = 'Today';
                } else if ( new Date(temp[index].nextVisitDateTime).getTime() < currentDate.getTime()) {
                  temp[index].dateStatus = 'Late';
                } else {
                  temp[index].dateStatus = 'Upcoming';
                }
              }
            }
            this.visitLIst = temp
            this.resetPaginationValues = false
          }
          
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        () => {
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    },
  }
};
</script>
<style scoped>
</style>